// All of the known Trimble EMS featureIds that are targeted at AddLocation
export enum AddlocationFeatureIds {
  LegacyFree = 'FEA-SKP-ADL-FREE',
  LegacySilver = 'FEA-SKP-ADL-SLVR',
  LegacyGold = 'FEA-SKP-ADL-GOLD',
  LegacyPlatinum = 'FEA-SKP-ADL-PLTN',

  // todo remove this as part of FE-969
  LargeAreaImport = 'FEA-SKP-ADL-LAI',

  Free = 'FEA-SKP-AL-FREE',
  Silver = 'FEA-SKP-AL-SLVR',
  Gold = 'FEA-SKP-AL-GOLD',
  Platinum = 'FEA-SKP-AL-PLTN',
}
