// Copyright 2018 - 2019 Trimble Inc. All Rights Reserved.
// Author: cole@sketchup.com (Cole Carroll)
// Handles getting entitlements from the SketchUp Entitlements Service.

import { ActivationDto, ActivationsResponseDto } from './entitlements/activations-response.dto';

const LARGE_AREA_IMPORT_FEATURE_ID = 'FEA-SKP-ADL-LAI';

// todo FE-1639  remove
export function isValidLargeAreaImportActivation(activations: ActivationsResponseDto): boolean {
  if (!activations.activations.length) {
    return false;
  }
  const activation = activations.activations[0];
  return (
    activation.authorized &&
    (isProOrStudio(activation) || isEduOrNprActivation(activation) || isAdvancedTrainingCenterActivation(activation)) &&
    activation.featureId?.includes(LARGE_AREA_IMPORT_FEATURE_ID)
  );
}

/**
 * Determines if an activation response contains a studio or pro activation. (or custom valid activations)
 */
function isProOrStudio(activationRes: ActivationDto): boolean {
  return (
    !activationRes.isTrial &&
    (activationRes.sku.startsWith('SKP-STDO') ||
      activationRes.sku.startsWith('SKP-PRO') ||
      activationRes.sku.startsWith('SKP-NFR') || // NFR sku is for internal testers and beta users.
      activationRes.sku.startsWith('HABITAT-ENTERPRISE'))
  ); // Enterprise customers
}

/**
 * Determines if an activation response contains an EDU, Not for profit, or advanced training center
 * activation.
 */
function isEduOrNprActivation(activationRes: ActivationDto): boolean {
  return !activationRes.isTrial && (activationRes.sku.includes('SKP-EDU') || activationRes.sku.startsWith('SKP-NPR'));
}

/**
 * Determines if an activation response contains an Advanced Training Center activation.
 */
function isAdvancedTrainingCenterActivation(activationRes: ActivationDto): boolean {
  return !activationRes.isTrial && activationRes.sku.startsWith('SKP-ATC');
}
