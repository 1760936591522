import { isValidLargeAreaImportActivation } from '../Entitlements';
import { Platform } from '../enums/platform.enum';
import { Stacks } from '../enums/stacks.enum';

import { ActivationDto, ActivationsResponseDto } from './activations-response.dto';
import { AddlocationFeatureIds } from './addlocation-feature-ids.enum';

type ActivationResponse =
  | { status: 401 | 403 }
  | {
      status: 200;
      data: ActivationsResponseDto;
    };

// todo FE-1639 remove
export interface ActivationPreference {
  sku: string;
  feature: string;
  other_sku?: string;
  other_feature?: string;
}

// temporary interface whilst we try to work out the
export class EntitlementsService {
  private readonly responseCache: Map<string, ActivationResponse> = new Map();

  constructor(
    private readonly stack: Stacks,
    private readonly platform: Platform,
  ) {}

  /**
   * Used only for reporting, extract the most valid activation for the user
   */
  async getActivationPreference(): Promise<ActivationPreference | undefined> {
    // todo FE-1639 remove call to get FEA-SKP-ADL
    const futureLegacyActivations = this.getActivations('FEA-SKP-ADL-LAI');
    const futureActivations = this.getActivations('FEA-SKP-AL');
    const [legacyActivations, activations] = await Promise.all([futureLegacyActivations, futureActivations]);

    let bestActivation: ActivationDto;
    let otherActivation: ActivationDto | undefined;
    if (activations.length === 0 && legacyActivations.length === 0) {
      return undefined;
    } else if (activations.length === 0) {
      // no new feature at all, pick legacy
      bestActivation = EntitlementsService.getLatestActivation(legacyActivations);
    } else if (legacyActivations.length === 0) {
      // no legacy feature at all, pick new
      bestActivation = EntitlementsService.getLatestActivation(activations);
    } else {
      // try and work out which one is going to give the best access to the product
      const latestLegacyActivation = EntitlementsService.getLatestActivation(legacyActivations);
      const latestActivation = EntitlementsService.getLatestActivation(activations);
      bestActivation = this.chooseActivation(latestLegacyActivation, latestActivation);
      otherActivation = bestActivation === latestLegacyActivation ? latestActivation : latestLegacyActivation;
    }
    return {
      sku: bestActivation.sku,
      feature: bestActivation.featureId,
      other_sku: otherActivation?.sku,
      other_feature: otherActivation?.featureId,
    };
  }

  /**
   * Chooses between a legacy activation and a new one based on expected "level of access"
   *
   * @param legacy the legacy activation
   * @param activation the new activation
   * @private the activation that is best for the user
   */
  private chooseActivation(legacy: ActivationDto, activation: ActivationDto) {
    switch (legacy.featureId) {
      case AddlocationFeatureIds.LegacyPlatinum:
        return activation.featureId === AddlocationFeatureIds.Platinum ? activation : legacy;
      case AddlocationFeatureIds.LegacyGold:
        return activation.featureId === AddlocationFeatureIds.Gold ? activation : legacy;
      case AddlocationFeatureIds.LegacySilver:
        return activation.featureId === AddlocationFeatureIds.Silver ? activation : legacy;
      case AddlocationFeatureIds.LargeAreaImport:
        // LAI is weird it gives more access than it should
        switch (activation.featureId) {
          case AddlocationFeatureIds.Gold:
          case AddlocationFeatureIds.Platinum:
          case AddlocationFeatureIds.Silver:
            // config looks fine, the new activation
            return activation;
          default:
            // looks like a configuration issue
            return legacy;
        }

      default:
        // config is probably fine
        return activation;
    }
  }

  private async getActivations(featureFilter: string): Promise<Array<ActivationDto>> {
    const response = await this.fetchActivations(featureFilter);
    if (response.status !== 200) {
      throw new Error(
        `Error retrieving entitlements at url=${this.getActivationUrl(featureFilter)}, unexpected response code ${
          response.status
        }`,
      );
    }

    const validActivations: Array<ActivationDto> = EntitlementsService.extractValidActivations(response.data);
    const anyActivation: Array<ActivationDto> = response.data.activations;
    return validActivations.length ? validActivations : anyActivation;
  }

  async getActivatedFeatures(): Promise<Set<string>> {
    if (this.platform === Platform.Schools) {
      // schools does not require a TID login, and no students will have any entitlements
      return new Set();
    }

    // todo FE-1639 remove call to get FEA-SKP-ADL and LargeAreaImport
    const [maybeLargeAreaImportFeatures, features] = await Promise.all([
      this.getActivationFeatures('FEA-SKP-AL'),
      this.getActivationFeatures(AddlocationFeatureIds.LargeAreaImport),
    ]);

    let legacyFeatures: Set<string>;
    if (maybeLargeAreaImportFeatures.size > 0) {
      legacyFeatures = maybeLargeAreaImportFeatures;
    } else {
      legacyFeatures = await this.getActivationFeatures('FEA-SKP-ADL');
    }

    // combine the features into a single set
    const combinedFeatures = new Set<string>();
    legacyFeatures.forEach(f => combinedFeatures.add(f));
    features.forEach(f => combinedFeatures.add(f));
    return combinedFeatures;
  }

  private async getActivationFeatures(featureFilter: string): Promise<Set<string>> {
    const url = `${this.getBaseUrl(this.stack)}/api/v1/activations?featureFilter=${featureFilter}`;
    const response = await this.fetchActivations(featureFilter);
    if (response.status !== 200) {
      return new Set();
    }

    const validFeatureIds = EntitlementsService.extractValidFeatureIds(response.data);
    // todo remove this as part of FE-1639
    if (!isValidLargeAreaImportActivation(response.data)) {
      validFeatureIds.delete(AddlocationFeatureIds.LargeAreaImport);
    }
    return validFeatureIds;
  }

  static getLatestActivation(activations: Array<ActivationDto>): ActivationDto {
    return activations.sort(
      (a: ActivationDto, b: ActivationDto) =>
        new Date(b.activationDate).getTime() - new Date(a.activationDate).getTime(),
    )[0];
  }

  static extractValidActivations(activationsResponse: ActivationsResponseDto): Array<ActivationDto> {
    const validActivations = activationsResponse.activations.filter(
      activation => activation.authorized && (activation.daysLeft === -1 || activation.daysLeft > 0),
    );
    return activationsResponse.errorCode === 'NO_ERROR' && validActivations.length > 0 ? validActivations : [];
  }

  static extractValidFeatureIds(activationsResponse: ActivationsResponseDto): Set<string> {
    const validActivations = this.extractValidActivations(activationsResponse);
    if (validActivations.length) {
      const featureIds = validActivations
        .map(activation => {
          const bestFeatureId = activation.featureId ? [activation.featureId] : [];
          const allFeatureIds = activation.allFeatureIds ? activation.allFeatureIds : [];
          return bestFeatureId.concat(allFeatureIds);
        })
        .reduce((a, b) => a.concat(b));
      return new Set(featureIds);
    } else {
      return new Set();
    }
  }

  private getActivationUrl(featureFilter: string): string {
    return `${this.getBaseUrl(this.stack)}/api/v1/activations?featureFilter=${featureFilter}`;
  }

  private async fetchActivations(featureFilter: string): Promise<ActivationResponse> {
    if (this.responseCache.has(featureFilter)) {
      // use the cached response
      return this.responseCache.get(featureFilter);
    }

    const url = this.getActivationUrl(featureFilter);
    const response = await fetch(url, {
      credentials: 'include',
      headers: [['Accept', 'application/json']],
    });

    let activationResponse: ActivationResponse;
    if (response.ok) {
      activationResponse = {
        status: 200,
        data: await response.json(),
      };

      // hack to prevent trial users from having lower access than they should
      activationResponse.data.activations.forEach(activation => {
        if (activation.isTrial && activation.featureId === AddlocationFeatureIds.Silver) {
          // due to a configuration issue, the platinum trials may have silver treat them as
          activation.featureId = AddlocationFeatureIds.Platinum;
        }
      });
    } else if (response.status === 401 || response.status === 403) {
      // not logged in. we can cache this
      activationResponse = { status: response.status };
    } else {
      throw new Error(`Error retrieving entitlements at url=${url}, unexpected response code ${response.status}`);
    }

    this.responseCache.set(featureFilter, activationResponse);
    return activationResponse;
  }

  // Entitlements url for different stacks.
  private getBaseUrl(stack: Stacks): string {
    switch (stack) {
      case Stacks.Dev:
        return 'https://dev-entitlements.sketchup.com';
      case Stacks.Stg:
        return 'https://stg-entitlements.sketchup.com';
      default:
        return 'https://entitlements.sketchup.com';
    }
  }
}
